<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <form>
        <md-card>
          <md-card-header
              class="md-card-header-icon"
              :class="getClass(headerColor)"
          >
            <div class="card-icon">
              <template v-if="oper==='add'">
                <md-icon>post_add</md-icon>
              </template>
              <template v-else>
                <md-icon>mode_edit</md-icon>
              </template>

            </div>
            <h4 class="title">
              {{ $t(`road.${oper}`) }} {{ ` ${road.road_id ? road.road_id : ""}` }}
              <small></small>
            </h4>
          </md-card-header>
          <md-card-content>
            <div class="md-layout md-size-50">
              <div class="md-layout-item md-small-size-100 md-size-50">
                <div class="md-layout-item md-small-size-100 md-size-50">
                  <md-field v-if='this.oper !== "upd"'>
                    <label>
                      {{ $t(`road.road_id`) }}
                    </label>
                    <md-input
                        v-model="road.road_id"
                        :type="'road_road_id'"
                        :data-vv-name="'road_id'"
                        @input="onFieldChange('road_id')"
                    ></md-input>
                  </md-field>
                  <md-field>
                    <label>
                      {{ $t(`road.name`) }}
                    </label>
                    <md-input
                        v-model="road.road_name"
                        :type="'road_name'"
                        :data-vv-name="'road_name'"
                        @input="onFieldChange('road_name')"
                    ></md-input>
                  </md-field>
                  <md-field>
                    <label>
                      {{ $t(`road.category`) }}
                    </label>
                    <md-input
                        v-model="road.road_category"
                        @input="onFieldChange('road_category')"
                    ></md-input>
                  </md-field>
                  <md-field>
                    <label>
                      {{ $t(`road.road_class`) }}
                    </label>
                    <md-select
                        v-model="road.road_class"
                        @input="onFieldChange('items')"
                    >
                      <md-option v-for="id in roadClassList" :key="id" :value="id">{{ id }}
                      </md-option>
                    </md-select>
                  </md-field>

                </div>
              </div>
              <div class="md-layout-item md-size-100 text-right">
                <md-button
                    class="md-success"
                    native-type="submit"
                    @click.native.prevent="validate"
                    :disabled="nothingSave"
                >
                  {{ $t('button.save') }}
                </md-button>
                <md-button class="md-accent" @click.stop.prevent="onCancel">
                  {{ $t('button.close') }}
                </md-button>
              </div>
            </div>
          </md-card-content>
        </md-card>
      </form>
    </div>
  </div>
</template>
<script>

//import {SlideYDownTransition} from 'vue2-transitions'
import Swal from 'sweetalert2'

export default {
  name: 'road-profile-form',
  props: {
    headerColor: {
      type: String,
      default: ''
    },
    oper: String
  },
  components: {
    //SlideYDownTransition
  },
  async created() {
    this.roadClassList = await this.$store.dispatch("LOAD_ROAD_CLASS_LIST")

    if (this.oper === "upd") {
      const roads = await this.$store.dispatch("LOAD_ROAD_LIST")
      this.road = roads.find(road => road.road_id === this.roadId)
    }
  },
  data() {
    return {
      nothingSave: true,
      road: {
        road_name: '',
        road_items: [],
        comment: ''
      },
      roadClassList: [],
      roadId: this.$route.params.road_id
    }
  },
  methods: {
    onFieldChange() {
      this.nothingSave = false
    },
    onCancel() {
      this.$router.go(-1)
    },
    getClass: function () {
      //return 'md-card-header-' + headerColor + ''
    },
    async validate() {
      const alert = {
        type: 'success',
        text: '',
        footer: ''
      }

      const {road_id, road_name, road_category, road_class} = this.road
      try {
        if (this.oper === "add") {
          await this.$store.dispatch('ADD_ROAD',
              {
                road_id, road_name, road_category, road_class
              })
          alert.text = this.$t(`label.road_was_added`)
        } else if (this.oper === "upd") {
          await this.$store.dispatch('UPDATE_ROAD', {
            roadId: this.roadId,
            data: {road_name, road_category, road_class}
          })
          alert.text = this.$t(`label.road_was_updated`)
        } else {
          console.log("Unknown operation")
        }

        this.nothingSave = true
        Swal.fire(alert).then(() => {
          //this.touched.category = false
          this.$nextTick(() => this.$validator.reset())
        })
      } catch (err) {
        console.log(err)
        alert.type = 'error'
        if (this.oper === "add") {
          alert.text = this.$t(`plan.record_was_not_added`)
        } else if (this.oper === "upd") {
          alert.text = this.$t(`plan.record_was_not_updated`)
        }
        //alert.footer = this.$t(err)
        Swal.fire(alert)
      }
    }
  },
  computed: {}
}
</script>
<style>
.md-button + .md-button {
  margin-left: 10px;
}
</style>
